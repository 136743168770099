











































































































// @ is an alias to /src

import { MutationPayload } from 'vuex';
import Modal from '@/components/modal.vue';
import Input from '@/components/form/input.vue';
import DatePicker from '@/components/form/date-picker.vue';
import Textarea from '@/components/form/textarea.vue';
import Checkbox from '@/components/form/checkbox.vue';
import store from '@/store';
import ColorPicker from '@/components/form/color-picker.vue';
import { getFormFields, getFormQuestions, getProductForms } from '@/api/forms';
import { IFormQuestion } from '@/models/form';
import router from '@/router';
import Cropper from '@/components/form/cropper.vue';
import { uploadFile } from '@/api/files';
import {
  addProductCategory,
  addProductSubCategory,
  getCategories,
  getSubCategories,
} from '@/api/categories';
import { ICategory } from '@/models/category';
import { IProduct } from '@/models/product';
import { ICheckbox } from '@/components/form';
import { form, formInputs, modal, statusSelect } from '../index';
import { defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';

export default defineComponent({
  components: {
    Modal,
    Input,
    Textarea,
    DatePicker,
    ColorPicker,
    Checkbox,
    Cropper,
  },
  data() {
    return {
      modal: modal,
      modalCropper: { isActive: false, isCard: true },
      product: {},
      form: form,
      questions: [] as IFormQuestion[],
      categories: [] as ICategory[],
      inputs: [...formInputs],
      categoryInputs: [] as (ICheckbox & { children?: ICheckbox[] })[],
      event: 0,
      statusSelect: statusSelect(),
      exhibitor: 0,
      submitted: false,
      loading: false,
      formId: 0,
      cropper: {
        image: '',
        onSelect: (blob: string) => {
          console.log(blob);
        },
        aspectRatio: 4 / 3,
      },
      cropping: true,
      croppedFileName: '',
      confirming: false,
      cropSrc: '',
      images: [] as File[],
      imageIndex: -1,
      productSubCategories: [] as number[],
      productCategories: [] as number[],
    };
  },
  setup() {
    const { translations } = useContext();
    return { translations };
  },
  async created() {
    this.cropper.onSelect = this.onSelectCrop;
    this.event = parseInt(this.$route.params.event, 10);
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
    this.statusSelect = statusSelect();

    const { data } = await getSubCategories({ event: this.event });
    if (data && data.results) {
      const subCategories = data.results;
      getCategories({ event: this.event }).then((response) => {
        this.categories = response.data.results;
        this.categories.forEach((category) => {
          const inputChildren = [] as ICheckbox[];

          const children = subCategories.filter(
            (subCategory) => subCategory.category === category.id,
          );
          if (children && children.length > 0) {
            children.forEach((subCategory) => {
              inputChildren.push({
                id: `product-subcategory_${subCategory.id}`,
                name: `product_subcategory_${subCategory.id}`,
                value: false,
                type: 'checkbox',
                label: subCategory.name,
                onClick: (value: string) => {
                  if (value === 'on') {
                    this.productSubCategories.push(subCategory.id);
                  } else {
                    this.productSubCategories = this.productSubCategories.filter(
                      (productSubCategory) => subCategory.id !== productSubCategory,
                    );
                  }
                  console.log(this.productSubCategories);
                },
              });
            });
          }
          this.categoryInputs.push({
            id: `product-category_${category.id}`,
            name: `product_category_${category.id}`,
            value: false,
            type: 'checkbox',
            label: category.name,
            onClick: (value: string) => {
              if (value === 'on') {
                this.productCategories.push(category.id);
              } else {
                this.productCategories = this.productCategories.filter(
                  (productCategory) => category.id !== productCategory,
                );
              }
              console.log(this.productCategories);
            },
            children: inputChildren,
          });
        });
        this.$forceUpdate();
      });
    }

    getProductForms(this.event).then(async (response) => {
      if (response.data.results[0].id) {
        this.formId = response.data.results[0].id;
        const responseFormFields = await getFormFields(this.formId);
        const fields = responseFormFields.data.results;
        getFormQuestions(this.formId).then((response) => {
          this.questions = response.data.results;
          this.questions.forEach((question, i) => {
            const field = fields.find((field) => field.id === question.form_field);
            if (field) {
              const index = i;
              if (field.type_field > 5) {
                this.inputs.push({
                  id: `exhibitor-${question.id}`,
                  name: `exhibitor_${question.id}`,
                  label: question.display_name,
                  placeholder: question.placeholder,
                  type: 'file',
                  message: question.required ? this.translations.common.mandatory_field : undefined,
                  onFileSelect: (file: File) => {
                    this.selectImage(file, index);
                  },
                  value: '',
                });
              } else {
                this.inputs.push({
                  id: `exhibitor-${question.id}`,
                  name: `exhibitor_${question.id}`,
                  label: question.display_name,
                  placeholder: question.placeholder,
                  type: field.name === 'Description' ? 'textarea' : 'text',
                  message: question.required ? this.translations.common.mandatory_field : undefined,
                  onInput: (value: string | number) => this.clearInput(index, value),
                  value: '',
                });
              }
            }
          });
        });
      }
    });
  },
  methods: {
    selectImage(file: File, index: number) {
      this.croppedFileName = file.name;
      if (file.name.match(/.(jpg|jpeg|png|gif)$/i)) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e && e.target && e.target.result) {
            this.cropper = { ...this.cropper, image: e.target.result as string };
            this.cropping = true;
            this.confirming = false;
            this.modalCropper.isActive = true;
            this.imageIndex = index;
          }
        };
        reader.readAsDataURL(file);
      } else {
        this.inputs[index] = {
          ...this.inputs[index],
          value: '',
          message: this.translations.common.file_not_image,
          error: true,
        } as any;
        this.$forceUpdate();
      }
    },

    onSelectCrop(blob: string) {
      const file = new File([blob], this.croppedFileName, { type: 'image/png' });
      const urlCreator = window.URL || window.webkitURL;
      this.cropSrc = urlCreator.createObjectURL(blob);
      this.images[this.imageIndex] = file;
      this.cropping = false;
      this.confirming = true;
    },

    confirmCrop() {
      this.modalCropper.isActive = false;
    },

    cancel() {
      this.modalCropper.isActive = false;
    },

    cancelCrop() {
      this.cropping = true;
      this.confirming = false;
    },

    clearInput(index: number, value: string | number) {
      if (this.inputs[index].error) {
        this.inputs[index] = {
          ...this.inputs[index],
          error: false,
          message: '',
          value: value as string,
        };
        this.$forceUpdate();
      }
    },

    async create() {
      this.loading = true;
      for (const index in this.images) {
        const image = this.images[index];
        const imageForm = new FormData();
        imageForm.append('file', image);
        const response = await uploadFile(imageForm);
        this.questions[index].answer = `${response.data.id}`;
      }

      store.dispatch('addProduct', {
        payload: {
          submitted: this.submitted,
          product: {
            form: this.formId,
            exhibitor: this.exhibitor,
          },
          questions: this.questions,
        },
        exhibitor: this.exhibitor,
        event: this.event,
      });

      const unsubscribe = store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'newProduct') {
          const product = mutation.payload as IProduct;
          this.productCategories.forEach(async (productCategory) => {
            await addProductCategory({
              category: productCategory,
              product: product.id,
            });
          });
          this.productSubCategories.forEach(async (productSubCategory) => {
            await addProductSubCategory({
              subcategory: productSubCategory,
              product: product.id,
            });
          });
          store.dispatch('fetchProducts', {
            event: this.event,
            page: 1,
            page_size: 10,
            exhibitor: this.exhibitor,
          });
          router.push(`/event/${this.event}/manage-exhibitor/${this.exhibitor}/products/`);
          this.loading = false;
          unsubscribe();
        }

        if (mutation.type === 'setProductsError') {
          const errors = mutation.payload;
          this.loading = false;

          for (const [key, value] of Object.entries(errors)) {
            this.inputs[key as any] = {
              ...this.inputs[key as any],
              message: (<string[]>value)[0],
              error: true,
            } as any;
          }

          store.commit('addPopup', {
            message: this.translations.common.something_went_wrong,
            type: 'danger',
            autohide: true,
          });
        }
      });
    },
  },
});
