import axios from 'axios';
import {
  ICategoriesParams, ICategory, ISubCategory,
} from '@/models/category';
import { Resolve, IData } from '.';
import config from './config';

const url = process.env.VUE_APP_SERVER_PATH;

export const getCategories = (params: ICategoriesParams) => new Promise((resolve: Resolve<IData<ICategory>>) => {
  if (!params.page_size) {
    params.page_size = 100;
  }
  if (!params.page) {
    params.page = 1;
  }

  let requestUrl = `${url}/v2/categories?page_size=${params.page_size}&page=${params.page}&ordering=order`;

  if (params.event) {
    requestUrl += `&event=${params.event}`;
  }

  axios
    .get(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const getExhibitorCategories = (params: ICategoriesParams) => new Promise((resolve: Resolve<IData<ICategory>>) => {
  if (!params.page_size) {
    params.page_size = 100;
  }
  if (!params.page) {
    params.page = 1;
  }

  let requestUrl = `${url}/v2/event-exhibitor-categories?page_size=${params.page_size}&page=${params.page}&ordering=order`;

  if (params.event) {
    requestUrl += `&event=${params.event}`;
  }

  axios
    .get(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const getSubCategories = (params: ICategoriesParams) => new Promise((resolve: Resolve<IData<ISubCategory>>) => {
  if (!params.page_size) {
    params.page_size = 100;
  }
  if (!params.page) {
    params.page = 1;
  }

  let requestUrl = `${url}/v2/subcategories?page_size=${params.page_size}&page=${params.page}&ordering=order`;

  if (params.event) {
    requestUrl += `&event=${params.event}`;
  }
  if (params.category_name) {
    requestUrl += `&category__name=${params.category_name}`;
  }
  if (params.category) {
    requestUrl += `&category=${params.category}`;
  }

  axios
    .get(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const addProductCategory = (productCategory: { category: number, product: number }) => new Promise((resolve: Resolve<IData<ICategory>>) => {
  const requestUrl = `${url}/v2/product-categories`;

  axios
    .post(requestUrl, productCategory, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});
export const editProductCategory = (productCategory: { category: number, product: number }) => new Promise((resolve: Resolve<IData<ICategory>>) => {
  const requestUrl = `${url}/v2/product-categories`;

  axios
    .patch(requestUrl, productCategory, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const addProductSubCategory = (productSubCategory: { subcategory: number, product: number }) => new Promise((resolve: Resolve<IData<ICategory>>) => {
  const requestUrl = `${url}/v2/product-subcategories`;

  axios
    .post(requestUrl, productSubCategory, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const editProductSubCategory = (productSubCategory: { subcategory: number, product: number }) => new Promise((resolve: Resolve<IData<ICategory>>) => {
  const requestUrl = `${url}/v2/product-subcategories`;

  axios
    .patch(requestUrl, productSubCategory, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const deleteProductCategory = (productCategory: { category: number, product: number }) => new Promise((resolve: Resolve<IData<ICategory>>) => {
  const requestUrl = `${url}/v2/product-categories/${productCategory.category}`;

  axios
    .delete(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const deleteProductSubCategory = (productSubCategory: { subcategory: number, product: number }) => new Promise((resolve: Resolve<IData<ICategory>>) => {
  const requestUrl = `${url}/v2/product-subcategories/${productSubCategory.subcategory}`;

  axios
    .delete(requestUrl, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});
