<template>
  <div class="field color-picker">
    <div class="field-label is-normal" v-if="config.label">
      <label class="label">{{ config.label }}</label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="control has-icons-right" :class="{ 'is-loading': config.loading }">
          <verte picker="square" model="hex" v-model="config.value"></verte>

          <input
            class="input"
            :name="config.name"
            :type="config.type"
            :value="config.value"
            :placeholder="config.placeholder"
            :maxlength="config.maxLength ? config.maxLength : 100"
            @focus="onFocus"
            @blur="onBlur"
            v-on:change="updateValue($event.target.value, $event)"
            @input="($event) => updateValue($event.target.value, $event)"
            @click="inputClick()"
          />

          <span class="icon is-small is-right">
            <i class="material-icons-outlined">color_lens</i>
          </span>
        </div>
        <span class="label-message" v-if="!config.error && config.message">
          <span class="icon">
            <i class="material-icons-outlined">info</i>
          </span>
          <span style="vertical-align: text-bottom">
            {{ config.message }}
          </span>
        </span>
        <span class="label-error" v-if="config.error">
          <span class="icon">
            <i class="material-icons-outlined">info</i>
          </span>
          <span style="vertical-align: text-bottom">
            {{ config.message }}
          </span></span
        >
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { defineComponent, watch } from '@vue/composition-api';
import Verte from 'verte';
import 'verte/dist/verte.css';

export default defineComponent({
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  components: {
    Verte,
  },
  setup(props, { emit }) {
    const onFocus = (e) => {
      if (props.config.onFocus) {
        props.config.onFocus(e);
      }
    };

    const onBlur = () => {
      if (props.config.onBlur) {
        this.config.onBlur();
      }
    };

    const updateValue = (value, $event) => {
      if (!this.config.maxValue || this.config.maxValue > parseInt(value, 10)) {
        if (this.config.type === 'number') {
          this.prevValue = value;
          this.$emit('input', parseInt(value, 10));
        } else {
          this.prevValue = value;
          this.$emit('input', value);
        }
        this.config.error = false;
        if (this.config.onInput) {
          this.config.onInput(value);
        }
      } else if (value && this.config.maxValue && $event.target) {
        // eslint-disable-next-line no-param-reassign
        $event.target.value = this.prevValue;
      }
    };

    const inputClick = () => {
      const $pickerBtn = document.querySelector('.verte .verte__guide');
      if ($pickerBtn) {
        $pickerBtn.click();
      }
    };

    watch(
      () => props.config.value,
      (before, after) => {
        emit('input', props.config.value);
      },
    );

    return {
      value: props.config.value,
      onFocus,
      onBlur,
      updateValue,
      inputClick,
    };
  },
});
</script>
<style lang="scss">
.field.color-picker {
  .verte {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    padding-bottom: calc(0.5rem - 1px);
    padding-left: calc(0.75rem - 1px);
    padding-right: calc(0.75rem - 1px);
    padding-top: calc(0.6rem - 1px);
    z-index: 1;
    .verte__menu-origin {
      left: calc(0.75em - 1px);
    }
    .verte__controller {
      .slider {
        display: none;
      }
    }
  }
  .input {
    padding-left: 3rem;
    cursor: pointer;
  }
}
</style>
